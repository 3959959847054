$(function() {
    // M.AutoInit();
    $(".dropdown-trigger").dropdown({coverTrigger:false, constrainWidth: false});

    // 検索閉じるボタン
    $("#search-close").click(function(){
        $("#search").val('');
    });

    // 削除確認
    $(".delete-confirm").click(function(){
        if (confirm('削除します。よろしいですか？')) {
            $('<form/>', {action: $(this).attr('href'), method: 'post'})
            .append($('<input/>', {type: 'hidden', name: '_token', value: $('meta[name="csrf-token"]').attr('content')}))
            .append($('<input/>', {type: 'hidden', name: '_method', value: 'delete'}))
            .appendTo(document.body)
            .submit();
        }
        return false;
    });

    // 復元確認
    $(".restore-confirm").click(function(){
        if (confirm('ゴミ箱から復元します。よろしいですか？')) {
            $('<form/>', {action: $(this).attr('href'), method: 'post'})
            .append($('<input/>', {type: 'hidden', name: '_token', value: $('meta[name="csrf-token"]').attr('content')}))
            .append($('<input/>', {type: 'hidden', name: '_method', value: 'put'}))
            .appendTo(document.body)
            .submit();
        }
        return false;
    });

    // 公開設定確認
    $(".open-edit-confirm").click(function(){
        var url = $(this).attr('href');
        if (url.slice(-1) == 0) {
            var msg = '投稿を非公開に設定します。よろしいですか？';
        } else {
            var msg = '投稿を公開に設定します。よろしいですか？';
        }
        if (confirm(msg)) {
            $('<form/>', {action: url, method: 'post'})
            .append($('<input/>', {type: 'hidden', name: '_token', value: $('meta[name="csrf-token"]').attr('content')}))
            .append($('<input/>', {type: 'hidden', name: '_method', value: 'put'}))
            .appendTo(document.body)
            .submit();
        }
        return false;
    });
});
